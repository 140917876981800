<template>
  <div class="loginBox">
    <div class="topImg">
      <img src="../assets/logo_blue.png" alt="" />
    </div>
    <div class="safe">
      <div class="downContent">
        <div>
          <img :src="text" alt="" class="textImg" />
          <img :src="bj_blue" alt="" />
        </div>
        <div class="loginContent">
          <h2 class="title">账号登录</h2>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="密码登录" name="second"></el-tab-pane>
            <el-tab-pane label="验证码登录" name="first"></el-tab-pane>
          </el-tabs>
          <div class="content">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <el-form-item label="" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入手机号"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <div class="codeRow" v-if="activeName == 'first'">
                <div class="grid-content bg-purple">
                  <el-form-item label="" prop="code">
                    <el-input
                      v-model="ruleForm.code"
                      placeholder="请输入验证码"
                      maxlength="11"
                    ></el-input>
                  </el-form-item>
                </div>
                <span
                  class="getCodeBtn"
                  @click="getCheckCode"
                  v-if="showSendCode"
                  >获取验证码</span
                >
                <span class="getCodeBtn" v-else>{{ miao }}后重新发送</span>
              </div>
              <el-form-item label="" prop="account_password" v-else>
                <el-input
                  v-model="ruleForm.account_password"
                  show-password
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item> -->
            </el-form>
            <el-button
              type="primary"
              class="loginBtn"
              @click="submitForm('ruleForm')"
              v-if="activeName == 'first'"
              >登录</el-button
            >
            <el-button
              v-else
              type="primary"
              class="loginBtn"
              @click="submitFormPassword('ruleForm')"
              >登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCode, login, passwordLogin } from "@/api/order.js";
import bj_blue from "@/assets/newbg.png";
import text from "@/assets/text.png";
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      bj_blue,
      text,
      input: "",
      activeName: "second",
      ruleForm: {
        phone: "",
        code: "",
        account_password: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        account_password: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      showSendCode: true,
      miao: 59,
    };
  },
  created() {
    window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handkeyCode, true); //关闭监听键盘按下事件
  },
  methods: {
    handkeyCode(e) {
      if (e.keyCode === 13) {
        this.submitFormPassword("ruleForm");
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 发送验证码
    getCheckCode() {
      let flag = false;
      this.$refs.ruleForm.validateField("phone", (err) => {
        if (err) {
          flag = true;
        }
      });
      if (flag) {
        return;
      }
      getCode({ number: this.ruleForm.phone }).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        if (res.code != 200) {
          return;
        } else {
          this.showSendCode = false;
          let a = setInterval(() => {
            this.miao--;
            if (this.miao <= 0) {
              this.showSendCode = true;
              this.miao = 59;
              clearInterval(a);
            }
          }, 1000);
        }
      });
    },
    // 登录
    submitForm(formphone) {
      this.$refs[formphone].validate((valid) => {
        if (valid) {
          let params = {
            number: this.ruleForm.phone,
            sms_code: this.ruleForm.code,
          };
          login({ ...params }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            localStorage.setItem("userInfo", JSON.stringify(res.userinfo));
            localStorage.setItem("token", res.token);
            const token = localStorage.getItem("token");
            this.$router.push({ name: "newIndex" });
          });
        } else {
          return false;
        }
      });
    },
    // 密码登录
    submitFormPassword(formphone) {
      this.$refs[formphone].validate((valid) => {
        if (valid) {
          let params = {
            number: this.ruleForm.phone,
            account_password: this.ruleForm.account_password,
          };
          passwordLogin({ ...params }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            localStorage.setItem("userInfo", JSON.stringify(res.userinfo));
            localStorage.setItem("token", res.token);
            const token = localStorage.getItem("token");
            this.$router.push({ name: "newIndex" });
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formphone) {
      this.$refs[formphone].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.loginBox {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .safe {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.topImg {
  padding-bottom: 120px;
  box-sizing: border-box;
  img {
    width: 178px;
    // height: 46px;
    position: absolute;
    top: 62px;
    left: 80px;
    cursor: pointer;
    display: block;
  }
}
.downContent {
  display: flex;
  width: 100%;
  justify-content: center;
  img {
    display: block;
    height: 700px;
    margin-left: 100px;
  }
  .textImg {
    display: block;
    height: 60px;
    margin: auto;
    margin-bottom: 10px;
  }
}
.loginContent {
  width: 560px;
  height: 613px;
  padding: 0 35px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-left: 100px;
  flex: 0 0 560px;
  .title {
    padding: 36px 0;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }
  .codeRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .getCodeBtn {
      display: inline-block;
      padding: 20px 16px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 4px;
      color: #fff;
      background: #4285f4;
      transition: all 0.1s linear;
      user-select: none;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .loginBtn {
    margin-top: 40px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 18px;
    background: #4285f4;
    &:hover {
      background: #689df6;
    }
  }
}
::v-deep .el-input__inner {
  height: 65px;
  line-height: 65px;
  font-size: large;
}
::v-deep .grid-content {
  flex: 1;
  margin-right: 18px;
}
::v-deep .el-tabs__item {
  font-size: 18px;
  user-select: none;
}
</style>
